import React, { useEffect } from 'react';

const MessageListener = () => {
    useEffect(() => {
        console.log('MessageListener se ha montado');

        // Selecciona el elemento del badge de notificaciones
        const notificationBadge = document.querySelector('.MuiBadge-badge');

        if (notificationBadge) {
            console.log('Badge de notificaciones encontrado:', notificationBadge);

            // Crea una instancia de MutationObserver
            const observer = new MutationObserver((mutationsList) => {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'childList' || mutation.type === 'characterData') {
                        // Detecta si el contenido del badge ha cambiado
                        const notificationCount = notificationBadge.textContent;
                        console.log('Cambió el contenido del badge:', notificationCount);
                        if (notificationCount && parseInt(notificationCount) > 0) {
                            // Aquí puedes notificar a Android Studio a través de la WebView
                            console.log('Nuevo mensaje detectado');
                            window.Android.onNewMessage(); // Suponiendo que tienes esta función definida en Android
                        }
                    }
                }
            });

            // Configura el observador para observar cambios en los hijos y datos del nodo
            observer.observe(notificationBadge, { childList: true, characterData: true, subtree: true });

            // Limpia el observador cuando el componente se desmonta
            return () => {
                observer.disconnect();
                console.log('MessageListener se ha desmontado');
            };
        } else {
            console.log('Badge de notificaciones no encontrado');
        }
    }, []);

    return null;
};

export default MessageListener;
