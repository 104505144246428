import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { head } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { i18n } from "../../translate/i18n";
import moment from "moment";
import 'moment/locale/es'

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import ConfirmationModal from "../ConfirmationModal";

moment.locale('es')
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#fff"
    },

    tabmsg: {
        backgroundColor: theme.palette.campaigntab,
    },

    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const CampaignSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    message1: Yup.string().min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required")
});

const EventModal = ({
    open,
    onClose,
    campaignId,
    initialValues,
    onSave,
    resetPagination,
}) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);
    const { companyId } = user;
    const [file, setFile] = useState(null);

    const initialState = {
        name: "",
        message1: "",
        whatsappId: "",
        month: '',
        day: '',
        time: '',
        reference: "1"
    };

    const [campaign, setCampaign] = useState(initialState);
    const [whatsapps, setWhatsapps] = useState([]);
    const [contactLists, setContactLists] = useState([]);
    const [messageTab, setMessageTab] = useState(0);
    const [attachment, setAttachment] = useState(null);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [campaignEditable, setCampaignEditable] = useState(true);
    const attachmentFile = useRef(null);
    const [tagLists, setTagLists] = useState([]);
    const [month, setMonth] = useState(initialState.month);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/files/", {
                    params: { companyId }
                });

                setFile(data.files);
            } catch (err) {
                toastError(err);
            }
        })();
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            if (initialValues) {
                setCampaign((prevState) => {
                    return { ...prevState, ...initialValues };
                });
            }

            api
                .get(`/contact-lists/list`, { params: { companyId } })
                .then(({ data }) => setContactLists(data));

            api
                .get(`/whatsapp`, { params: { companyId, session: 0 } })
                .then(({ data }) => setWhatsapps(data));

            // api.get(`/tags`, { params: { companyId } })
            //   .then(({ data }) => {
            //     const fetchedTags = data.tags;
            //     // Perform any necessary data transformation here
            //     const formattedTagLists = fetchedTags.map((tag) => ({
            //       id: tag.id,
            //       name: tag.name,
            //     }));
            //     setTagLists(formattedTagLists);
            //   })
            //   .catch((error) => {
            //     console.error("Error retrieving tags:", error);
            //   });

            if (!campaignId) return;

            api.get(`/eventos?id=${campaignId}`).then(({ data }) => {
                setCampaign((prev) => {
                    let prevCampaignData = Object.assign({}, prev);

                    Object.entries(data[0]).forEach(([key, value]) => {
                        prevCampaignData[key] = value === null ? "" : value;
                    });

                    return prevCampaignData;
                });
            });
        }
    }, [campaignId, open, initialValues, companyId]);


    const handleClose = () => {
        onClose();
        setCampaign(initialState);
    };

    const handleAttachmentFile = (e) => {
        const file = head(e.target.files);
        if (file) {
            setAttachment(file);
        }
    };

    const handleSaveCampaign = async (values) => {
        try {
            let seguimientoId = campaignId;
            const dataValues = {};
            for (const [key, value] of Object.entries(values)) {

                dataValues[key] = value !== "" ? value : null;
                if(key === 'time') dataValues[key] = `1970-01-01 ${value}`;
            };


            if (seguimientoId) {
                await api({
                    method: 'put',
                    url: `/eventos/${seguimientoId}`,
                    data: dataValues,
                });

                handleClose();
            } else {
                const { data } = await api({
                    method: "post",
                    url: "/eventos",
                    data: dataValues,
                });

                seguimientoId = data.id;

            }
            if (attachment != null) {
                const formData = new FormData();
                formData.append("file", attachment);
                await api.put(`/eventos/${seguimientoId}`, formData);
            }
            if (onSave) {
                onSave();
            }
            handleClose();
            toast.success(i18n.t("campaigns.toasts.success"));
        } catch (err) {
            console.log(err);
            toastError(err);
        }
    };

    // const deleteMedia = async () => {
    //   if (attachment) {
    //     setAttachment(null);
    //     attachmentFile.current.value = null;
    //   }

    //   if (campaign.mediaPath) {
    //     await api.delete(`/campaigns/${campaign.id}/media-upload`);
    //     setCampaign((prev) => ({ ...prev, mediaPath: null, mediaName: null }));
    //     toast.success(i18n.t("campaigns.toasts.deleted"));
    //   }
    // };

    const renderMessageField = (identifier) => {
        return (
            <Field
                as={TextField}
                id={identifier}
                name={identifier}
                fullWidth
                rows={5}
                label={i18n.t(`campaigns.dialog.form.${identifier}`)}
                placeholder={i18n.t("campaigns.dialog.form.messagePlaceholder")}
                multiline={true}
                variant="outlined"
                helperText="Utilize variables como {nome}, {numero}, {email} o defina sus varibles perzonalizados."
                disabled={!campaignEditable && campaign.status !== "CANCELADA"}
            />
        );
    };

    const renderConfirmationMessageField = (identifier) => {
        return (
            <Field
                as={TextField}
                id={identifier}
                name={identifier}
                fullWidth
                rows={5}
                label={i18n.t(`campaigns.dialog.form.${identifier}`)}
                placeholder={i18n.t("campaigns.dialog.form.messagePlaceholder")}
                multiline={true}
                variant="outlined"
                disabled={!campaignEditable && campaign.status !== "CANCELADA"}
            />
        );
    };



    return (
        <div className={classes.root}>
            <ConfirmationModal
                title={i18n.t("campaigns.confirmationModal.deleteTitle")}
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
            // onConfirm={deleteMedia}
            >
                {i18n.t("campaigns.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {campaignEditable ? (
                        <>
                            {campaignId
                                ? `Editar seguimiento`
                                : `Nuevo seguimiento`}
                        </>
                    ) : (
                        <>{`${i18n.t("campaigns.dialog.readonly")}`}</>
                    )}
                </DialogTitle>
                <div style={{ display: "none" }}>
                    <input
                        type="file"
                        ref={attachmentFile}
                        onChange={(e) => handleAttachmentFile(e)}
                    />
                </div>
                <Formik
                    initialValues={campaign}
                    enableReinitialize={true}
                    validationSchema={CampaignSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveCampaign(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                        <Form>
                            <DialogContent dividers>
                                <Grid spacing={2} container>

                                    {/* nome */}
                                    <Grid xs={12} md={6} item>


                                        <Field
                                            as={TextField}
                                            label={i18n.t("campaigns.dialog.form.name")}
                                            name="name"
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            className={classes.textField}
                                            disabled={!campaignEditable}
                                        />
                                    </Grid>

                                    {/* whatsapp */}
                                    <Grid xs={12} md={6} item>
                                        <FormControl
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            className={classes.formControl}
                                        >
                                            <InputLabel id="whatsapp-selection-label">
                                                {i18n.t("campaigns.dialog.form.whatsapp")}
                                            </InputLabel>
                                            <Field
                                                as={Select}
                                                label={i18n.t("campaigns.dialog.form.whatsapp")}
                                                placeholder={i18n.t("campaigns.dialog.form.whatsapp")}
                                                labelId="whatsapp-selection-label"
                                                id="whatsappId"
                                                name="whatsappId"
                                                error={touched.whatsappId && Boolean(errors.whatsappId)}
                                                disabled={!campaignEditable}
                                            >
                                                <MenuItem value="">Ninguna</MenuItem>
                                                {whatsapps &&
                                                    whatsapps.map((whatsapp) => (
                                                        <MenuItem key={whatsapp.id} value={whatsapp.id}>
                                                            {whatsapp.name}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12} item>
                                        
                                        <RadioGroup 
                                            name="reference" 
                                            row
                                            error={touched.reference && Boolean(errors.reference)}
                                            helperText={touched.reference && errors.reference}
                                            onChange={({target: {value}}) => setFieldValue('reference',value)}
                                            value={values.reference}
                                        >
                                        <FormControlLabel
                                                    value="1"
                                                    
                                                    control={<Radio color="primary"/>}
                                                    label="Fecha de nacimiento"
                                                    labelPlacement="start"
                                                />
                                                <FormControlLabel
                                                    value="2"
                                                    
                                                    control={<Radio color="primary"/>}
                                                    label="Fecha de creacion"
                                                    labelPlacement="start"
                                                
                                                />
                                                <FormControlLabel
                                                    value="3"
                                                    
                                                    control={<Radio color="primary"/>}
                                                    label="Fecha personalizada"
                                                    labelPlacement="start"
                                                
                                                />
                                        </RadioGroup>
                                    </Grid>

                                    {/* mes */}
                                   { values.reference == "3" && 
                                   
                                   <>
                                   <Grid xs={12} md={4} item>
                                        <Field
                                            as={Select}
                                            label={`Mes`}
                                            placeholder={`Mes`}
                                            labelId="whatsapp-selection-label"
                                            id="month"
                                            name="month"
                                            type="number"
                                            error={touched.periodo && Boolean(errors.periodo)}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            className={classes.textField}
                                            disabled={!campaignEditable}
                                        >
                                            <MenuItem value="">Ninguna</MenuItem>
                                            {moment.months().map((mes, i) => (
                                                <MenuItem key={`mes-${mes}`} value={i + 1}>
                                                    {mes}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>

                                        {/* dia */}
                                    <Grid xs={12} md={4} item>

                                        <Field
                                            as={Select}
                                            label={`Dia`}
                                            placeholder={`Dia en dias`}
                                            labelId="whatsapp-selection-label"
                                            id="day"
                                            name="day"
                                            type="number"
                                            error={touched.periodo && Boolean(errors.periodo)}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            className={classes.textField}
                                            disabled={!campaignEditable}
                                        >
                                            <MenuItem value="">Ninguna</MenuItem>
                                            {Array.from(new Array(moment(new Date(1970, values.month, 1)).daysInMonth()), (_, i) => i + 1).map((day) => (
                                                <MenuItem key={`day-${day}`} value={day}>
                                                    {day}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>

                                            {/* hora */}
                                    <Grid xs={12} md={4} item>


                                        <Field
                                            as={TextField}
                                            label={"Hora"}
                                            name="time"
                                            error={touched.sendTime && Boolean(errors.sendTime)}
                                            helperText={touched.sendTime && errors.sendTime}
                                            variant="outlined"
                                            margin="dense"
                                            type="time"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            style={{ width: 140, margin: 0 }}
                                        //   disabled={!campaignEditable}
                                        />

                                    </Grid>
                                    </>
                                    }

                                    {/* mensagem */}
                                    <Grid xs={12} item>
                                        <Tabs
                                            value={messageTab}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            className={classes.tabmsg}
                                            onChange={(e, v) => setMessageTab(v)}
                                            variant="fullWidth"
                                            centered
                                            style={{
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Tab label="Msg. 1" index={0} />

                                        </Tabs>
                                        <Box style={{ paddingTop: 20, border: "none" }}>
                                            {messageTab === 0 && (
                                                <>
                                                    {values.confirmation ? (
                                                        <Grid spacing={2} container>
                                                            <Grid xs={12} md={8} item>
                                                                <>{renderMessageField("message1")}</>
                                                            </Grid>
                                                            <Grid xs={12} md={4} item>
                                                                <>
                                                                    {renderConfirmationMessageField(
                                                                        "confirmationMessage1"
                                                                    )}
                                                                </>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <>{renderMessageField("message1")}</>
                                                    )}
                                                </>
                                            )}
                                            {messageTab === 1 && (
                                                <>
                                                    {values.confirmation ? (
                                                        <Grid spacing={2} container>
                                                            <Grid xs={12} md={8} item>
                                                                <>{renderMessageField("message2")}</>
                                                            </Grid>
                                                            <Grid xs={12} md={4} item>
                                                                <>
                                                                    {renderConfirmationMessageField(
                                                                        "confirmationMessage2"
                                                                    )}
                                                                </>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <>{renderMessageField("message2")}</>
                                                    )}
                                                </>
                                            )}
                                            {messageTab === 2 && (
                                                <>
                                                    {values.confirmation ? (
                                                        <Grid spacing={2} container>
                                                            <Grid xs={12} md={8} item>
                                                                <>{renderMessageField("message3")}</>
                                                            </Grid>
                                                            <Grid xs={12} md={4} item>
                                                                <>
                                                                    {renderConfirmationMessageField(
                                                                        "confirmationMessage3"
                                                                    )}
                                                                </>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <>{renderMessageField("message3")}</>
                                                    )}
                                                </>
                                            )}
                                            {messageTab === 3 && (
                                                <>
                                                    {values.confirmation ? (
                                                        <Grid spacing={2} container>
                                                            <Grid xs={12} md={8} item>
                                                                <>{renderMessageField("message4")}</>
                                                            </Grid>
                                                            <Grid xs={12} md={4} item>
                                                                <>
                                                                    {renderConfirmationMessageField(
                                                                        "confirmationMessage4"
                                                                    )}
                                                                </>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <>{renderMessageField("message4")}</>
                                                    )}
                                                </>
                                            )}
                                            {messageTab === 4 && (
                                                <>
                                                    {values.confirmation ? (
                                                        <Grid spacing={2} container>
                                                            <Grid xs={12} md={8} item>
                                                                <>{renderMessageField("message5")}</>
                                                            </Grid>
                                                            <Grid xs={12} md={4} item>
                                                                <>
                                                                    {renderConfirmationMessageField(
                                                                        "confirmationMessage5"
                                                                    )}
                                                                </>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <>{renderMessageField("message5")}</>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                    {(campaign.mediaPath || attachment) && (
                                        <Grid xs={12} item>
                                            <Button startIcon={<AttachFileIcon />}>
                                                {attachment != null
                                                    ? attachment.name
                                                    : campaign.mediaName}
                                            </Button>
                                            {campaignEditable && (
                                                <IconButton
                                                    onClick={() => setConfirmationOpen(true)}
                                                    color="secondary"
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                {/*  */}
                                {!attachment && !campaign.mediaPath && campaignEditable && (
                                    <Button
                                        color="primary"
                                        onClick={() => attachmentFile.current.click()}
                                        disabled={isSubmitting}
                                        variant="outlined"
                                    >
                                        {i18n.t("campaigns.dialog.buttons.attach")}
                                    </Button>
                                )}
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("campaigns.dialog.buttons.close")}
                                </Button>
                                {(campaignEditable || campaign.status === "CANCELADA") && (
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                    >
                                        {campaignId
                                            ? `${i18n.t("campaigns.dialog.buttons.edit")}`
                                            : `${i18n.t("campaigns.dialog.buttons.add")}`}
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                )}
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default EventModal;
