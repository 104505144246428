import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import 'bootstrap/dist/css/bootstrap.min.css';

import App from "./App";
import MessageListener from "./MessageListener"; // Asegúrate de que la ruta sea correcta

ReactDOM.render(
	<React.StrictMode>
		<CssBaseline>
			<App />
			<MessageListener />
		</CssBaseline>
	</React.StrictMode>,
	document.getElementById("root")
);

// ReactDOM.render(
// 	<React.StrictMode>
// 		<CssBaseline>
// 			<App />
// 		</CssBaseline>,
//   </React.StrictMode>

// 	document.getElementById("root")
// );
